<template>
    <v-container
        fill-height
        fluid
        class="pa-0"
        style="min-height: 460px"
    >
        <v-card
            v-if="checkNewDocument"
            flat
            class="pr-4 pb-4 pl-4"
        >
            <tab-title
                :title="$t('interface.termsConditions.heading1')"
                :text="$t('interface.termsConditions.text1', {name: getMemberChannel.ss_kurzname})"
                :tooltip="false"
            />
            <v-expansion-panels
                mandatory
                light
                v-model="panel"
            >
                <v-expansion-panel :disabled="!getDependingDisables() || !confirmationSnippetState().state">
                    <v-expansion-panel-header :style="`color:${confirmationSnippetState().color}`" ripple>
                        <div class="text-truncate">
                            <v-icon :color="confirmationSnippetState().color">
                                {{ confirmationSnippetState().icon }}
                            </v-icon>
                            <span class="pl-2">
                            {{ confirmationSnippetState().message }}
                        </span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="auto">
                                <v-checkbox
                                    ripple
                                    dense
                                    v-model="confirmCheckbox"
                                    :disabled="!getDependingDisables()"
                                    :color="$store.getters.getColors.success"
                                    :label="$t('interface.termsConditions.confirmToConfirm')"
                                    :value="true"
                                    hide-details
                                />
                            </v-col>
                            <v-spacer/>
                            <v-col cols="auto">
                                <v-btn
                                    :loading="confirmBtnLoader"
                                    :disabled="!getDependingDisables() || !confirmCheckbox"
                                    class="mt-4"
                                    small
                                    :color="getColors.success"
                                    :style="`color:#fff`"
                                    @click="submitDocument(true)"
                                >
                                    {{ $t('interface.termsConditions.confirmDocument') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :disabled="confirmCheckbox">
                    <v-expansion-panel-header :style="`color:${getColors.error}`" ripple>
                        <div class="text-truncate">
                            <v-icon :color="getColors.error">
                                mdi-text-box-remove-outline
                            </v-icon>
                            <span class="pl-2">
                            {{ $t('interface.termsConditions.reportProblems') }}
                        </span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form class="ml-3 container">
                            <v-row>
                                <v-col cols="8">
                                    <Form/>
                                </v-col>
                            </v-row>
                            <v-row class="justify-end">
                                <v-col cols="auto">
                                    <v-btn
                                        :loading="complaintsBtnLoader"
                                        :disabled="getDependingDisables()"
                                        class="mt-4"
                                        small
                                        :color="getColors.error"
                                        :style="`color:#fff`"
                                        @click="submitDocument(false)"
                                    >
                                        {{ $t('interface.termsConditions.submitComplaints') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <v-container v-else>
            <v-row class="align-center justify-center">
                <v-col cols="auto">
                    <v-row class="justify-center">
                        <v-col cols="auto">
                            <v-icon :color="getStatusTextIconColor().color" size="64">
                                {{ getStatusTextIconColor().icon }}
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="justify-center">
                        <v-col cols="auto">
                        <span class="text-h4">
                            {{ getStatusTextIconColor().title }}
                        </span>
                        </v-col>
                    </v-row>
                    <v-row class="justify-center">
                        <v-col cols="auto" class="pb-0">
                            <div class="text--primary text-center">
                                <span v-html="getStatusTextIconColor().text"/>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="chips.length" class="justify-center">
                        <v-col cols="col-xl-7 col-lg-8 col-md-9 col-sm-10">
                            <div class="text--primary text-center">
                                <v-divider class="my-4"/>
                                <v-chip
                                    v-for="(value, index) in chips"
                                    :key="`complientIndex-${index}`"
                                    class="ma-2"
                                    text-color="#fff"
                                    :color="getColors.error"
                                >
                                    {{ value }}
                                </v-chip>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="complaintReasonsFromMs.additionalNotes" class="my-0 justify-center">
                        <v-col class="col-xl-7 col-lg-8 col-md-9 col-sm-10">
                            <v-divider class="my-4"/>
                            <v-card flat>
                                <v-card-title class="pl-1">
                                    {{ getAgentNameAdditionalNotes() }}
                                </v-card-title>
                                <v-sheet class="elevation-2 pa-4 text-left">
                                    {{ complaintReasonsFromMs.additionalNotes }}
                                </v-sheet>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <div v-if="gotPdf" class="w-100 mt-16">
            <v-slider
                class="cursor-pointer mx-auto"
                :style="`width: 60%`"
                prepend-icon="mdi-magnify-minus-outline"
                @click:prepend="scaleByClick(0)"
                append-icon="mdi-magnify-plus-outline"
                @click:append="scaleByClick(1)"
                v-model="slider"
                :track-color="getColors.primaryOpacity05"
                :color="getColors.primary"
                :thumb-color="getColors.secondary2"
                thumb-label="always"
                min="20"
            >
                <template v-slot:thumb-label="{ value }">
                    {{ `${ value }%` }}
                </template>
            </v-slider>
            <v-card flat class="w-100 d-flex flex-wrap justify-center">
                <div
                    :style="`width:${slider}%`"
                    class="ma-2 elevation-2"
                    v-for="i in numPages"
                    :key="i"
                >
                    <pdf :src="src" :key="`key-for-page${i}`" :page="i"/>
                </div>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog.vue'
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import CheckboxField from '@/components/Formulare/Felder/CheckboxField.vue'
import Form from '@/components/Formulare/Formular.vue'
import SubmitAction from '@/components/Formulare/Absenden.vue'
import TTSuperadminChanges from '@/components/Tooltips/TTAenderungenNurSuperAdmin.vue'
import membersService from '@/services/members.service'
import { mapGetters } from 'vuex'
import moment from 'moment'
import pdf from '@teckel/vue-pdf'

export default {
    name: 'BasicDataTab',
    props: {
        checkNewDocument: Boolean,
        documentUnavailable: Boolean,
    },
    components: {
        CheckboxField,
        Dialog,
        TabTitle,
        Form,
        SubmitAction,
        TTSuperadminChanges,
        pdf,
    },
    data() {
        return {
            slider: 60,
            gotPdf: false,
            chips: [],
            msDocHash: '',
            src: undefined,
            numPages: 0,
            waitForNewDocument: false,
            confirmBtnLoader: false,
            confirmCheckbox: false,
            complaintReasonsFromMs: {},
            complaintsBtnLoader: false,
            panel: [0],
            formDataModel: {
                checkResult: false,
                fileHash: '',
                contactDetails: 0,
                logoPresent: 0,
                socialmediaPresent: 0,
                ownWebsite: 0,
                additionalNotes: '',
            },
        }
    },
    computed: {
        ...mapGetters([
            'getMembers',
            'getMember',
            'getColors',
            'getForm',
            'getTab',
            'getLocale',
            'getUser',
            'getMemberChannel',
            'getMemberChannels',
            'getInterface',
            'getClients',
            'getRouting',
            'getDialog',
            'getLoaders',
        ]),
        formData: {
            get() {
                return this.getForm
            },
            set(newVal) {
                this.$store.dispatch('setForm', newVal)
            },
        },
        models: function () {
            return [
                {
                    label: this.$i18n.t('interface.termsConditions.complaints.contactDetails'),
                    source: 'contactDetails',
                    element: 'checkbox',
                },
                {
                    label: this.$i18n.t('interface.termsConditions.complaints.logoPresent'),
                    source: 'logoPresent',
                    element: 'checkbox',
                },
                {
                    label: this.$i18n.t('interface.termsConditions.complaints.socialmediaPresent'),
                    source: 'socialmediaPresent',
                    element: 'checkbox',
                },
                {
                    label: this.$i18n.t('interface.termsConditions.complaints.ownWebsite'),
                    source: 'ownWebsite',
                    element: 'checkbox',
                },
                {
                    label: this.$i18n.t('interface.termsConditions.complaints.additionalMessage'),
                    source: 'additionalNotes',
                    element: 'textarea',
                },
            ]
        },
    },
    watch: {
        //Info: Reassign loader, models and form in the store as soon as the tab in member is changed
        '$store.state.tab': async function (tabState) {
            if (tabState === 1) {
                await this.$store.dispatch('setModels', this.models)
                await this.$store.dispatch('setForms', this.formDataModel)
            }
        },
        '$store.state.interface': async function () {
            if (this.getTab === 1) {
                this.$emit('update:documentUnavailable', false)
                await this.handleContentState()
            }
        },
        '$store.state.locale': async function () {
            await this.$store.dispatch('setModels', this.models)
            await this.$store.dispatch('setForms', this.formDataModel)
            this.getComplaintChips()
            this.getAgentNameAdditionalNotes()
        },
    },
    methods: {
        scaleByClick(dir) {
            if (dir === 0 && this.slider <= 100)
                this.slider -= 5
            else if (dir === 1 && this.slider >= 20)
                this.slider += 5
        },
        confirmationSnippetState() {
            const hasGoogle = this.getMemberChannels[1].find(elem => elem.ss_id === 31).ss_activatedformember
            const hasClients = this.getClients.length
            const state = (hasGoogle && hasClients)

            let color = this.getColors.success
            let icon = 'mdi-text-box-check-outline'
            let message = this.$i18n.t('interface.termsConditions.confirmDocument')

            if (!state) {
                color = this.getColors.error
                icon = 'mdi-text-box-remove-outline'
                message = this.$i18n.t('interface.termsConditions.cannotConfirmGoogleAndClients')

                if (hasGoogle && !hasClients)
                    message = this.$i18n.t('interface.termsConditions.cannotConfirmClients')
                else if (!hasGoogle && hasClients)
                    message = this.$i18n.t('interface.termsConditions.cannotConfirmGoogle')
            }

            return {
                state: state,
                message: message,
                color: color,
                icon: icon,
            }
        },
        getDependingDisables() {
            const form = this.getForm
            const anyComplaintCheckbox = (form.contactDetails || form.logoPresent || form.socialmediaPresent || form.ownWebsite)
            const additionalNotes = form.additionalNotes

            return !(anyComplaintCheckbox || additionalNotes)
        },
        getAgentNameAdditionalNotes() {
            if (Object.keys(this.complaintReasonsFromMs).length && this.complaintReasonsFromMs.hasOwnProperty('agentId') && this.complaintReasonsFromMs.agentId) {

                logger.log(`this is this.complaintReasonsFromMs.agentId: `, this.complaintReasonsFromMs)

                const member = this.getMembers.find(elem => elem.id === this.complaintReasonsFromMs.agentId)
                return this.$i18n.t('interface.termsConditions.additionalMessage', { name: `${ member.firstname } ${ member.name }` })
            }

            return this.$i18n.t('interface.termsConditions.complaints.additionalMessage')
        },
        getComplaintChips() {
            this.chips.length = 0

            for (let i in this.complaintReasonsFromMs) {
                if (this.complaintReasonsFromMs.hasOwnProperty(i)) {
                    const value = this.complaintReasonsFromMs[i]
                    let chipTitle = ''

                    if (value > 0) {
                        switch (true) {
                            case i === 'contactDetails':
                                chipTitle = this.$i18n.t('interface.termsConditions.chips.contactDetails')

                                if (!this.chips.includes(chipTitle))
                                    this.chips.push(chipTitle)

                                break

                            case i === 'logoPresent':
                                chipTitle = this.$i18n.t('interface.termsConditions.chips.logoPresent')

                                if (!this.chips.includes(chipTitle))
                                    this.chips.push(chipTitle)

                                break

                            case i === 'ownWebsite':
                                chipTitle = this.$i18n.t('interface.termsConditions.chips.ownWebsite')

                                if (!this.chips.includes(chipTitle))
                                    this.chips.push(chipTitle)

                                break

                            case i === 'socialmediaPresent':
                                chipTitle = this.$i18n.t('interface.termsConditions.chips.socialmediaPresent')

                                if (!this.chips.includes(chipTitle))
                                    this.chips.push(chipTitle)

                                break
                        }
                    }
                }
            }
        },
        async getPdf() {

            logger.log('okok -> now get PDF !!, whith interfaceParams: ', this.getInterface)

            if (this.getInterface.hasOwnProperty('ms_parameter5') && this.getInterface.ms_parameter5) {
                const hashParameter = this.getInterface.ms_parameter5.slice(0, -4)

                this.src = pdf.createLoadingTask(`https://api.feondi.de/common/document.php?u=xsigns&p=sfgashfasdfajshbfkas&md5=${ hashParameter }&memberid=${ this.getMember.id }`)

                await this.src.promise.then(pdf => {
                    this.numPages = pdf.numPages

                    if (this.numPages)
                        this.gotPdf = true
                })
            }
        },
        getDateFromTs() {
            moment.locale(this.getLocale)
            return moment(this.complaintReasonsFromMs.tstamp).format('MMMM Do YYYY')
        },
        getStatusTextIconColor() {
            if (this.documentUnavailable) {
                return {
                    icon: 'mdi-book-cancel-outline',
                    color: this.getColors.error,
                    title: this.$i18n.t('interface.termsConditions.documentUnavailable.header'),
                    text: this.$i18n.t('interface.termsConditions.documentUnavailable.text'),
                }
            } else if (this.waitForNewDocument && !Object.keys(this.complaintReasonsFromMs).length) {
                return {
                    icon: 'mdi-clock-time-seven-outline',
                    color: this.getColors.warning,
                    title: this.$i18n.t('interface.termsConditions.waitForNewDocument.header1'),
                    text: this.$i18n.t('interface.termsConditions.waitForNewDocument.text1'),
                }
            } else if (this.waitForNewDocument && Object.keys(this.complaintReasonsFromMs).length) {
                return {
                    icon: 'mdi-clock-time-ten-outline',
                    color: this.getColors.error,
                    title: this.$i18n.t('interface.termsConditions.waitForNewDocument.header2'),
                    text: this.$i18n.t('interface.termsConditions.waitForNewDocument.text2', { date: this.getDateFromTs() }),
                }
            }

            return {
                icon: 'mdi-check-circle-outline',
                color: this.getColors.success,
                title: this.$i18n.t('interface.termsConditions.confirmed'),
                text: this.$i18n.t('interface.termsConditions.documentWillBeHere'),
            }
        },
        async submitDocument(confirmationStatus) {
            this.confirmBtnLoader = true

            const payload = {
                checkResult: confirmationStatus,
                secure: this.getRouting.params.secure,
                msid: this.$route.params.msid,
                ssid: this.$route.params.ssid,
                memberid: this.$route.params.memberid,
                member: this.getMember,
                fileHash: '',
            }

            if (this.getRouting.params.agb)
                payload.fileHash = this.getRouting.params.agb
            else if (this.msDocHash)
                payload.fileHash = this.msDocHash

            const response = await membersService.handleConditionsAndTerms({ ...this.formData, ...payload })

            if (response) {
                if (response.includes('The document is no longer available')) {
                    await this.$emit('update:documentUnavailable', true)
                    await this.$emit('update:checkNewDocument', false)
                    await this.handleContentState()
                } else {
                    this.$store.commit('SET_INTERFACE', response[0])
                    this.confirmCheckbox = false

                    await this.$store.dispatch('resetProps', ['routing', 'form'])

                    await this.handleContentState()

                    if (this.$route.params.hasOwnProperty('agb') && this.$route.params.agb)
                        await this.$router.replace(`/member/${ payload.memberid }/member-channel/${ payload.ssid }/interface/${ payload.msid }`)
                }

                this.confirmBtnLoader = false
            }
        },
        async handleContentState() {
            if (this.documentUnavailable) {
                this.$store.commit('RESET_PROP', 'routing')

                if (this.$route.params.hasOwnProperty('agb') && this.$route.params.agb)
                    await this.$router.replace(`/member/${ this.$route.params.memberid }/member-channel/${ this.$route.params.ssid }/interface/${ this.$route.params.msid }`)

            }
            //Info: If ssid is 32 feondi2ms_parameter4 exists and it can be checked for equality of hashes
            else if (!this.documentUnavailable && Object.keys(this.getInterface).length && this.getInterface.hasOwnProperty('feondi2ms_parameter4') && this.getInterface.hasOwnProperty('ms_parameter5')) {
                let complaintsReasonsObj = {}
                const complaintsReasonsJson = this.getInterface.feondi2ms_parameter6

                const hashFromMsWithExtension = this.getInterface.ms_parameter5
                const msHash = hashFromMsWithExtension.substring(0, hashFromMsWithExtension.length - 4)
                this.msDocHash = msHash

                const f2msHash = this.getInterface.feondi2ms_parameter4
                let complaintedHash = ''

                if (complaintsReasonsJson) {
                    complaintsReasonsObj = JSON.parse(complaintsReasonsJson)
                    complaintedHash = complaintsReasonsObj.complaintedHash
                }

                //logger.log(`#########################>>>>>>>>>>>>>>>>>>>>>>> this is msHash: ${ msHash }`)
                //logger.log(`#########################>>>>>>>>>>>>>>>>>>>>>>> this is f2msHash: ${ f2msHash }`)
                //logger.log(`#########################>>>>>>>>>>>>>>>>>>>>>>> this is f2msHash: ${ complaintedHash }`)
                //logger.log(`#########################>>>>>>>>>>>>>>>>>>>>>>> this is panel: ${ this.panel }`)

                this.chips = []
                this.waitForNewDocument = false
                this.complaintReasonsFromMs = complaintsReasonsObj

                await this.$store.dispatch('setForms', {
                    checkResult: false,
                    fileHash: '',
                    contactDetails: 0,
                    logoPresent: 0,
                    socialmediaPresent: 0,
                    ownWebsite: 0,
                    additionalNotes: '',
                })

                switch (true) {
                    //Info: A new document must be checked, that means: show form - ZEIGE FORM
                    //Habe einen Hash in der Memberschnittstelle, noch keinen in feondi2ms und nichts beanstandet
                    case msHash && !f2msHash && !complaintedHash:
                    //Habe einen hash in der Memberschnittstelle, noch keinen in feondi2ms und etwas beanstandet, auf die beanstandung wurde auch schon mit einem neuen file regaiert
                    case msHash && !f2msHash && complaintedHash && complaintedHash !== msHash:
                    //Habe einen neuen hash in der Memberschnittstelle der noch nicht beanstandet wurde erhalten und noch einen gültigen in feondi2ms
                    case msHash && f2msHash && msHash !== f2msHash && !complaintedHash:
                    //Habe einen neune hash in der Memberschnittstelle wessen vorgänger beanstandet wurde, sonlange der nicht bestätigt ist, gilt weiterhin der in feondi2ms
                    case msHash && f2msHash && msHash !== f2msHash && complaintedHash && complaintedHash !== msHash:

                        logger.log(`SHOW FOR TO FILL ------->>>`)

                        await this.$emit('update:checkNewDocument', true)

                        break

                    //Info: There are outstanding complaints that need to be reported - ZEIGE MELDUNGEN
                    case complaintedHash && complaintedHash === msHash:

                        logger.log(`SHOW COMPLAINTS CHIPS AND TEXT ------->>>`)

                        this.waitForNewDocument = true
                        await this.$emit('update:checkNewDocument', false)

                        this.getComplaintChips()

                        break

                    //Info: Currently waiting for a new document and show complaints - WARTE AUF ERSTES DOKUMENT
                    case !msHash && !f2msHash && !complaintedHash:

                        logger.log(`WAIT FOR FIRST DOCUMENT ------->>>`)

                        this.waitForNewDocument = true
                        await this.$emit('update:checkNewDocument', false)

                        break

                    //Info: The submitted document was accepted - AKZEPTIERT
                    default:

                        logger.log(`ACCEPTED ------->>>`)

                        this.complaintReasonsFromMs = {}
                        await this.$emit('update:checkNewDocument', false)

                        break
                }

            }
        },
    },
    async created() {
        await this.$store.dispatch('setModels', this.models)
        await this.$store.dispatch('setForms', this.formDataModel)

        await this.handleContentState()
        await this.getPdf()
    },
}
</script>
