<template>
  <div class="w-100 pl-4 pb-2 mb-4">
    <v-card class="pt-2" outlined>
      <member-heading-details
          channel-logo
          v-if="getMember && getMemberChannel && getInterface"
          :title-text="`MEMBER_FIRSTNAME MEMBER_NAME's (MEMBER_ID) ${getMemberChannel.ss_kurzname} ${$tc('common.words.interface')} ${(getInterface.ms_id) ? `(${getInterface.ms_id})` : ``}`"
          :subtitle-text="$t('common.memberHeadingDetails.interface', {name: getMemberChannel.ss_kurzname})"
      />
      <v-tabs
          v-model="tab"
          :background-color="getColors.primary"
          dark
          color="#ffffff"
          center-active
      >
        <v-tabs-slider :color="getColors.secondary1" />
        <v-tab
            v-for="(tabItem, index) in tabItems"
            :key="`tabItem-${index}`"
            :disabled="tabItem.disabled"
            @change="tab = index"
        >
          <v-progress-circular
              v-if="tabItem.disabled && tabItem.loading"
              class="mr-1"
              indeterminate
              size="16"
          />
          <v-icon v-else class="mr-1" small>
            {{ tabItem.icon }}
          </v-icon>
          {{ tabItem.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tabItem, index) in tabItems" :key="`tabItem-${index}`">
          <component
              :all-loaded="allLoaded"
              :check-new-document.sync="checkNewDocument"
              :document-unavailable.sync="documentUnavailable"
              v-bind:is="tabItem.component"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MemberHeadingDetails from '@/components/Allgemein/KundentitelMitDetails.vue'
import Form from '@/components/Formulare/Formular.vue'
import SubmitAction from '@/components/Formulare/Absenden.vue'
import BasicDataTabInterface from '@/components/Kunde/SchnittstellenverknuepfungStammdaten.vue'
import ReportTermsConditionsTab from '@/components/Kunde/AllgemeineGeschaeftsbedingungen.vue'
import membersService from '@/services/members.service'

export default {
    name: 'Interface',
    components: {
        MemberHeadingDetails,
        Form,
        SubmitAction,
        BasicDataTabInterface,
        ReportTermsConditionsTab,
    },
    metaInfo: {
        title: 'Interface',
    },
    data() {
        return {
            checkNewDocument: false,
            documentUnavailable: false,
            allLoaded: false,
            valid: true,
            createNewInterface: true,
            channel: {},
            models: [],
            msid: undefined,
            ssid: undefined,
            memberid: undefined,
        }
    },
    computed: {
        ...mapGetters([
            'getMember',
            'getForm',
            'getColors',
            'getRouting',
            'getActingMemberid',
            'getMemberChannels',
            'getMemberChannel',
            'getTab',
            'getInterface',
        ]),
        tab: {
            get() {
                return this.getTab
            },
            set(newValue) {
                this.$store.commit('SET_TAB', newValue)
            },
        },
        tabItems: function () {
            const tabs = [
                {
                    title: this.$t('common.tabs.basicData'),
                    icon: 'mdi-account-edit-outline',
                    component: 'BasicDataTabInterface',
                },
            ]

            logger.log(`this is ssid: ${this.ssid}`)

            if (this.ssid === 32) {
                tabs.push({
                    title: this.$t('common.tabs.tc'),
                    icon: 'mdi-gavel',
                    component: 'ReportTermsConditionsTab',
                    disabled: false,
                    loading: false,
                })
            }

            return tabs
        },
    },
    async created() {
        await this.$store.dispatch('getAndSetMembers')

        logger.log(`this is route params: `, this.$route.params)

        //Info: If there is no memberid yet, because you can get directly on the route without crossing the members
        if (this.$route.params.memberid) {
            this.memberid = parseInt(this.$route.params.memberid)

            if (this.memberid !== this.getActingMemberid)
                await this.$store.dispatch('startActingAsMember', this.memberid)

            logger.log(`this is getMember from store: `, this.$store.getters.getMember)
        }

        //Info: Set member if no one is set yet. This must be done when you come directly to the route
        if (this.memberid && ((this.getMember.hasOwnProperty('id') && this.getMember.id !== this.memberid) || !this.getMember.hasOwnProperty('id')))
            this.$store.commit('SET_MEMBER', this.memberid)

        //Info: If no msid comes via the params, then one comes from the channel and is about to create a new msid (createNew)
        if (this.$route.params.msid)
            this.msid = parseInt(this.$route.params.msid)

        //Info: The ssid is always there and we only set it here to have it also local in this.ssid
        if (this.$route.params.ssid)
            this.ssid = parseInt(this.$route.params.ssid)

        //Info: Fetches only the channels, if there are none yet, in order to be able to set the channel afterward
        await this.$store.dispatch('getAndSetMemberChannels', { memberid: this.memberid, force: true })

        //Info: Set channel if no one is set yet. This must be done when you come directly to the route
        if (this.ssid) {
            for (let i in this.getMemberChannels) {
                for (let j in this.getMemberChannels[i]) {
                    const channel = this.getMemberChannels[i][j]

                    if (channel.ss_id === this.ssid)
                        this.channel = channel

                }
            }

            this.$store.commit('SET_MEMBER_CHANNEL', this.channel)
        }

        //Info: Fetches only the interfaces, if there are none yet, in order to be able to set the interface afterwards
        await this.$store.dispatch('getAndSetInterfaces', { memberid: this.memberid, force: true })

        await this.$store.dispatch('getAndSetClients', { memberid: this.memberid, force: true })

        //Info: Set interface if none is set yet. This must be done if you come directly to the route
        if (this.msid) {
            this.$store.commit('SET_INTERFACE', this.msid)

            if (this.tabItems.length > 1 && (!this.getInterface.hasOwnProperty('feondi2ms_id') || !this.getInterface.feondi2ms_id))
                this.tabItems[1].disabled = true
        }

        //Info: Check if the route is directly driven to update the tabs and validate the T&Cs and secure in the backend
        if (Object.keys(this.getRouting.params).length && this.getRouting.params.hasOwnProperty('agb')) {
            const response = await membersService.checkConditionsAndTermsFile(this.getRouting.params)

            if (response.fileExists)
                this.checkNewDocument = true
            else
                this.documentUnavailable = true

            this.tab = 1
        }

        this.allLoaded = true
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('resetProps', ['interface', 'routing', 'tab'])
        this.$store.dispatch('resetForms')
        next()
    },
}
</script>
