<template>

  <v-row>
    <div class="col-auto pr-0">
      {{ title }}
    </div>
    <div class="col-1 align-self-center">
      <div :class="`point ${active ? 'point-green point-active' : 'point-red'}`"></div>
    </div>
  </v-row>
</template>

<script>
export default {
    name: 'StatusRadarpunkt',
    props: {
        title: {
            type: String,
            required: false,
        },
        active: {
            type: Boolean,
            required: false,
        },
    },
}
</script>

<style>
.point {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.point-green {
    background: rgb(22, 160, 133);
}

.point-red {
    background: rgb(231, 76, 60);
}

.point-active {
    animation: blink-green 2s ease-in-out infinite;
}

.point-inactive {
    animation: blink-red 2s ease-in-out infinite;
}

@keyframes blink-green {
    0% {
        box-shadow: 0 0 0 0 rgb(22, 160, 133);
    }
    100% {
        box-shadow: 0 0 0 8px rgba(187, 222, 251, 0.0);
    }
}

@keyframes blink-red {
    0% {
        box-shadow: 0 0 0 0 rgb(231, 76, 60);
    }
    100% {
        box-shadow: 0 0 0 8px rgba(187, 222, 251, 0.0);
    }
}
</style>
