<template>
  <div>
    <Dialog
        :data="{
            title: 'Are you sure to set new user credentials?',
            text: 'When new credentials are set, the affected portal must be notified in order to continue receiving data from Feondi.',
            replyAction: {
                state: true,
                btnText: 'Create new credentials'
            }
        }"
        @replyAction="createNewUserCredentials"
    />
    <v-card flat class="pr-4 pb-4 pl-4">
      <div v-if="allLoaded">
        <tab-title
            :title="$t('interface.heading', {msid: interface.ms_id, memberid: getMember.id})"
            :tooltip="true"
            :ssid="ssid"
            :passwordisset="(interface.ms_passwordisset ? interface.ms_passwordisset === 1 : false)"
        />
        <v-banner v-if="ssid === 23" color="warning" class="my-2">
          <v-icon color="white" size="36">
            mdi-alert
          </v-icon>
          <div style="display:inline-block" class="pa-4 white--text">
            {{ $t('interface.websiteAPICalenderURLInfoBanner') }}
          </div>
        </v-banner>

        <v-row v-if="interface.ms_ssid === 21">
          <div class="mt-0 mb-8 col-12">
            <status-radarpunkt
                :title="$tc('interface.feondiSyncActive', (interface.feondi2ms_parameter1 === 1 ? 1 : 0))"
                :active="interface.feondi2ms_parameter1 === 1" />
            <v-divider class="mt-4" />
          </div>
        </v-row>

        <v-form class="ml-3" ref="form" v-model="valid">
          <v-row>
            <v-col cols="6">
              <Form />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-auto">
              <SubmitAction
                  :valid="valid"
                  @submit="saveForm"
              />
            </v-col>
            <v-col class="col-auto">
              <v-btn
                  outlined
                  class="mt-6"
                  :color="getColors.error"
                  @click="cancel"
              >
                {{ $t('common.words.cancel') }}
              </v-btn>
            </v-col>

            <v-col v-if="ssid === 15" class="col-auto">
              <v-btn
                  outlined
                  class="mt-6 mr-6"
                  :color="getColors.primary"
                  @click="createNewUserCredentials"
              >
                {{ $t('common.buttons.createCredentials') }}
              </v-btn>
              <v-btn
                  outlined
                  class="mt-6"
                  :color="getColors.primary"
                  :disabled="!interface.ms_passwort && !interface.ms_plainpasswort"
                  @click="copyCredentialsToClipboard"
              >
                {{ $t('common.buttons.copyCredentials') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div v-else>
        <skeleton />
      </div>
    </v-card>
  </div>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog.vue'
import Skeleton from '@/components/Allgemein/Skeleton.vue'
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import Form from '@/components/Formulare/Formular.vue'
import SubmitAction from '@/components/Formulare/Absenden.vue'
import TTSuperadminChanges from '@/components/Tooltips/TTAenderungenNurSuperAdmin.vue'
import membersService from '@/services/members.service'
import utils from '@/utils/utils'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import StatusRadarpunkt from '@/components/Allgemein/StatusRadarpunkt.vue'

export default {
    name: 'BasicDataTabInterface',
    props: {
        allLoaded: Boolean,
    },
    components: {
        StatusRadarpunkt,
        Skeleton,
        Dialog,
        TabTitle,
        Form,
        SubmitAction,
        TTSuperadminChanges,
    },
    data() {
        return {
            valid: false,
            createNewInterface: true,
            channel: {},
            models: [],
            msid: undefined,
            ssid: undefined,
            memberid: undefined,
        }
    },
    computed: {
        ...mapGetters([
            'getMember',
            'getSubChannels',
            'getColors',
            'getForm',
            'getUser',
            'getMemberChannel',
            'getInterface',
        ]),
        interface: {
            get() {
                return this.getForm
            },
            set(newVal) {
                this.$store.dispatch('setForm', newVal)
            },
        },
    },
    watch: {
        //Info: Reassign loader, models and form in the store as soon as the tab in member is changed
        '$store.state.tab': async function (newState) {
            //Info: Check here also about isAuthenticated, because if you log out here and the cache is cleared, tab is again 0 and channels undefined
            if (newState === 0 && this.getMemberChannel.hasOwnProperty('ss_model') && this.getMemberChannel.ss_model) {
                this.channel = this.getMemberChannel
                this.handleDynamicFieldLabels()
                await this.$store.dispatch('setModels', this.models)

                if (this.msid)
                    this.interface = this.getInterface
            }
        },
        '$store.state.locale': function () {
            this.handleDynamicFieldLabels()

            if (this.msid)
                this.interface = this.getInterface
        },
        allLoaded: async function (newVal) {
            if (newVal)
                await this.handleRequirements()
        },
    },
    methods: {
        handleDynamicFieldLabels() {
            let tempModels = JSON.parse(this.channel.ss_model)

            tempModels.forEach(value => {
                value.label = this.$t(`common.labels.${value.label}`)
            })

            if (!this.msid) {
                const index = tempModels.findIndex(element => element.source === 'ms_id')
                if (index > -1)
                    tempModels.splice(index, 1)
            }

            this.models = tempModels
            this.$store.dispatch('setModels', this.models)
        },
        async copyCredentialsToClipboard() {
            const username = this.getForm.ms_username
            const password = this.interface.ms_plainpasswort
            if (username && password) {
                const copyValue = `${this.$t('common.labels.username')}: ${username}\n${this.$t('common.labels.password')}: ${password}`
                await utils.copyToClipboard(copyValue, true)
            }
        },
        async createNewUserCredentials() {
            const subChannel = this.getSubChannels.find(elem => elem.rapiif_id === this.getForm.ms_rapiifid)
            if (subChannel) {
                const chars = 'ABCDEFGHJKLMNPQRSTUVWXZYabcdefghijkmnopqrstuvwxyz23456789|*+#=!?$&§'
                let newPassword = ''
                for (let i = 0; i < 24; i++)
                    newPassword += chars.charAt(Math.floor(Math.random() * chars.length))

                this.interface = {
                    ...this.getForm, ...{
                        ms_passwort: newPassword,
                        ms_plainpasswort: newPassword,
                        ms_username: this.getMember.id + subChannel.rapiif_credentialtextid,
                    },
                }

                await this.copyCredentialsToClipboard()
            }
        },
        async handleRequirements() {
            //Info: If there is no memberid yet, because you can get directly on the route without crossing the members
            if (this.$route.params.memberid)
                this.memberid = parseInt(this.$route.params.memberid)

            //Info: If no msid comes via the params, then one comes from the channel and is about to create a new msid (createNew)
            if (this.$route.params.msid)
                this.msid = parseInt(this.$route.params.msid)

            //Info: The ssid is always there and we only set it here to have it also local in this.ssid
            if (this.$route.params.ssid)
                this.ssid = parseInt(this.$route.params.ssid)

            this.channel = this.getMemberChannel
            this.handleDynamicFieldLabels()

            //Info: Check if the msid was passed as parameter and make forms and interface ready for an update
            if (this.msid) {
                this.createNewInterface = false
                this.interface = this.getInterface
            }
            //Info: Since no msid was passed, a new memberinterface is created
            else {
                await this.$store.dispatch('setForms', {
                    ms_memberid: this.getMember.id,
                    ms_ssid: this.getMemberChannel.ss_id,
                })
            }

            await this.$store.dispatch('setModels', this.models)
            this.valid = true
        },
        async saveForm() {
            if (this.valid) {
                await this.$store.dispatch('setLoaders', { submit: true })
                const response = await membersService.setMemberInterface(this.getForm)

                if (response) {
                    this.$store.commit('SET_INTERFACES', response)
                    if (!this.createNewInterface) {
                        this.interface = response.find(elem => elem.ms_id === parseInt(this.msid))
                        this.$store.commit('SET_FORMCACHED', _.clone(this.getForm))
                    }

                    await this.$router.push({ path: `/member/${this.$route.params.memberid}/member-channel/${this.$route.params.ssid}` })
                    await this.$store.dispatch('setLoaders', { submit: false })

                    this.$socket.emit('broadcastEmitter', {
                        method: 'interfaceUpdate',
                        force: true,
                        memberid: parseInt(this.$route.params.memberid),
                        user: this.$store.getters.getUsernameTexts,
                    })

                    await this.$store.dispatch('successfulBanner', `Successfully edited interface ${this.msid} for channel ${this.$route.params.ssid}!`)
                }
            }
        },
        async cancel() {
            await this.$router.push({ path: `/member/${this.$route.params.memberid}/member-channel/${this.$route.params.ssid}` })
        },
    },
    async mounted() {
        await this.$store.dispatch('getAndSetSubChannels')

        if (this.allLoaded)
            await this.handleRequirements()
    },
}
</script>
